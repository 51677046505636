export default {
  //MemePad
  '0xd7f5c9befe1ea360807d5b99def5d121662c918a':
    'https://assets.coingecko.com/coins/images/15141/standard/1619694441492-Memepad_coin.png?1696514797',

  //Memecoin
  '0xda3db82f0b17b1abfccad6e0448653a514ee0aa3':
    'https://s2.coinmarketcap.com/static/cloud/img/dex/default-icon-day.svg?_=03c5d8f',

  // //YODA:
  '0xfd7ee8177a506071d749ff64db4ad1c6fbb90240':
    'https://pbs.twimg.com/profile_images/1710492703253839872/Wi-d4ymf_400x400.jpg',
  '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22':
    'https://assets.coingecko.com/coins/images/27008/large/cbeth.png?1696526061',
  '0x50c5725949a6f0c72e6c4a641f24049a917db0cb':
    'https://assets.coingecko.com/coins/images/9956/large/Badge_Dai.png?1696509996',
  '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913':
    'https://assets.coingecko.com/coins/images/6319/large/usdc.png?1696506694',
  '0x4200000000000000000000000000000000000006':
    'https://assets.coingecko.com/coins/images/2518/large/weth.png?1696503332',
  '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca':
    'https://assets.coingecko.com/coins/images/31164/large/baseusdc.jpg?1696529993',
  '0xd5046b976188eb40f6de40fb527f89c05b323385':
    'https://assets.coingecko.com/coins/images/31419/large/bsxlogo.png?1696530234',
  '0xeb466342c4d449bc9f53a865d5cb90586f405215':
    'https://assets.coingecko.com/coins/images/26476/large/uausdc_D_3x.png?1696525548',
  '0xfa980ced6895ac314e7de34ef1bfae90a5add21b':
    'https://assets.coingecko.com/coins/images/29053/large/prime-logo-small-border_%282%29.png?1696528020',
  '0xbd2dbb8ecea9743ca5b16423b4eaa26bdcfe5ed2':
    'https://assets.coingecko.com/coins/images/31190/large/synth.png?1696530018',
  '0x73ea165665d3a8c8df77970251291d8ad6015b66': 'https://basescan.org/token/images/daistablecoin_32.png',
  '0x031cc60bbcefc381eb57a7fae21199a16bb59886':
    'https://assets.coingecko.com/coins/images/2518/large/weth.png?1696503332',
}
