import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import _ from 'lodash'
import EthereumLogo from '../../assets/eth.png'
import symbolLogo from '../../constants/symbol-logo'
import assetLogo from '../../constants/assest-logo'

const cacheTokenLogo = (tokenAddress, urlImage) => {
  let getImage = localStorage.getItem('tokenLogo') || {}
  getImage = typeof getImage === 'string' && JSON.parse(getImage)
  if (getImage[tokenAddress?.toLowerCase()]) {
    return getImage[tokenAddress]
  }
  localStorage.setItem('tokenLogo', JSON.stringify({ ...getImage, [tokenAddress.toLowerCase()]: urlImage }))
  return urlImage
}

const getTokenLogo = (tokenAddress) => {
  let getImage = localStorage.getItem('tokenLogo') || {}
  getImage = typeof getImage === 'string' && JSON.parse(getImage)
  if (getImage[tokenAddress?.toLowerCase()]) {
    return getImage[tokenAddress]
  }

  return null
}

const fetchIconFromCoingecko = (tokenAddress) => {
  const url = `https://api.coingecko.com/api/v3/coins/base/contract/${tokenAddress}?x_cg_demo_api_key=CG-rhPTWRhy3DPg7gxd5ZqFfSYs`
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data?.image) {
        cacheTokenLogo(tokenAddress, data.image.large)
        return data.image
      }
      return null
    })
}

const fetchIcoFromCoinMarketcap = (tokenAddress) => {
  const url = `https://pro-api.coinmarketcap.com/v2/cryptocurrency/info?address=${tokenAddress}&aux=logo`
  const options = {
    headers: {
      'X-CMC_PRO_API_KEY': 'd0e6e07e-df99-4e29-85c8-a2badf962e47',
    },
  }
  return fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      // if (data?.data?.image) {
      //   cacheTokenLogo(tokenAddress, data.data.image)
      //   return data.data.image
      // }
      return null
    })
}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

export const TokenLogo = ({ address, header = false, size = '24px', ...rest }) => {
  const [error, setError] = useState(false)
  const [path, setPath] = useState(
    `https://github.com/trustwallet/assets/tree/master/blockchains/base/assets/${isAddress(address)}/logo.png`
  )

  useEffect(() => {
    const fetchIcon = async (tokenAddress) => {
      try {
        const checkCache = getTokenLogo(tokenAddress)
        if (!_.isEmpty(checkCache)) {
          setPath(checkCache)
        } else {
          const iconCoingecko = await fetchIconFromCoingecko(tokenAddress)
          if (iconCoingecko) {
            setPath(iconCoingecko?.large)
          }
        }
        setError(false)
      } catch (e) {
        setError(true)
        console.info('Load logo error', e)
        // try {
        //   const iconCoinMarketcap = await fetchIcoFromCoinMarketcap(tokenAddress)
        // } catch (e) {
        //   console.error(e)
        // }
      }
    }

    if (error && path === null) {
      fetchIcon(address)
    }
  }, [error])

  // eslint-disable-next-line react/prop-types
  const pathInCache = getTokenLogo(address?.toLowerCase())

  if (!_.isEmpty(pathInCache)) {
    return (
      <Inline>
        <Image {...rest} alt={'Icon Cache'} src={pathInCache} size={size} />
      </Inline>
    )
  }

  // eslint-disable-next-line react/prop-types
  const findLogoFromAsset = assetLogo[address?.toLowerCase()]
  if (!_.isEmpty(findLogoFromAsset)) {
    const pathLogo = findLogoFromAsset
    return (
      <Inline>
        <Image {...rest} alt={'Icon in asset'} src={pathLogo} size={size} />
      </Inline>
    )
  }

  const findCustomLogo = _.findKey(symbolLogo, function (token) {
    // eslint-disable-next-line react/prop-types
    return address?.toLowerCase() === token.address?.toLowerCase()
  })
  if (!_.isEmpty(findCustomLogo)) {
    const pathLogo = symbolLogo[findCustomLogo]?.logo
    return (
      <Inline>
        <Image {...rest} alt={'Custom Icon'} src={pathLogo} size={size} />
      </Inline>
    )
  }

  if (error || path === null) {
    return (
      <Inline>
        <span {...rest} alt={'icon default'} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={'Final icon'}
        src={path}
        size={size}
        onError={(event) => {
          setError(true)
          setPath(null)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}

export default React.memo(TokenLogo)
